<template>
  <NuxtI18nLink
    :to="gameLink"
    class="game"
    :class="{ mobile: props.isMobile }"
    data-t="game-link"
  >
    <div class="game-image" data-t="game-image" />
    <figcaption class="game-about">
      <h3 data-t="game-label" class="game-label">{{ item.name }}</h3>
      <p data-t="game-author" class="game-author">{{ item.provider }}</p>
    </figcaption>
    <div v-if="props.isMobile" class="chevron-icon">
      <StIcon :size="16" name="chevron-right" />
    </div>
  </NuxtI18nLink>
</template>
<script setup lang="ts">
import type { Game } from '../../../types'

const props = defineProps<{
  item: Game
  isMobile?: boolean
}>()
const imagePath = computed(() => `url("${props.item.image}"`)
const gameLink = computed(() =>
  props.isMobile
    ? `/mobile/casino/games/${props.item.gameId}?mode=real`
    : `/casino/games/${props.item.gameId}?mode=real`,
)
</script>
<style scoped>
.game-image {
  aspect-ratio: 16/10;
  width: 65px;
  height: 48px;

  background: v-bind(imagePath);
  background-position: center;
  background-size: cover;
  border-radius: var(--border-radius-050);
}

.game-label {
  margin: 0;
  font: var(--desktop-text-md-semibold);
}

.game-author {
  margin: 0;
  margin-top: var(var(--spacing-025));
  font: var(--desktop-text-xs-medium);
  color: var(--text-tertiary);
}

.game-about {
  display: flex;
  flex-direction: column;
  row-gap: var(--spacing-025);
  width: 100%;
}

a {
  all: unset;
}

.game {
  cursor: pointer;

  position: relative;

  display: flex;
  gap: var(--spacing-150);
  align-items: center;
  align-self: stretch;

  margin: 0;
  padding: var(--spacing-100) 0 var(--spacing-100) var(--spacing-100);

  border-radius: var(--border-radius-100);

  transition: background-color 0.2s ease-in-out;

  &:not(:first-child)::after {
    content: '';

    position: absolute;
    top: 0;
    right: var(--spacing-100);
    left: var(--spacing-100);

    height: 1px;

    background-color: rgb(255 255 255 / 8%);
  }

  &:hover {
    background: var(--background-secondary);

    &::after {
      opacity: 0;
    }

    + .game {
      &::after {
        opacity: 0;
      }
    }
  }

  &.mobile {
    padding: var(--spacing-100) var(--spacing-150);

    .game-label {
      font: var(--mobile-text-medium);
    }

    .game-author {
      font: var(--mobile-caption-1-regular);
    }
  }
}

.chevron-icon {
  display: flex;
  align-items: center;
  justify-content: center;

  padding: var(--spacing-050);

  color: var(--text-secondary);
}
</style>
