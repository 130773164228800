import type { TSportEvent } from 'markets-store'
import type { Game } from '@st/casino/types'

interface UseSearchReturn {
  tabs: ComputedRef<
    {
      id: string
      label: string
      counter: string
    }[]
  >
  resetSearch: () => void
  searchValue: Ref<string>
  sportEvents: Ref<TSportEvent[]>
  cybersportEvents: Ref<TSportEvent[]>
  casinoGames: Ref<Game[]>
  selectedTab: Ref<string>
  isLoading: ComputedRef<boolean>
}

export function useSearch(): UseSearchReturn {
  const { t } = useI18n()

  const searchValue = ref('')

  function resetSearch() {
    searchValue.value = ''
  }

  const { sportEvents, cybersportEvents } = useBookmakerSearch(searchValue)
  const { casinoGames, casinoPending } = useCasinoSearch(searchValue)

  const selectedTab = ref('casino')
  const tabs = computed(() => [
    {
      id: 'casino',
      label: t('search.casino'),
      counter: String(casinoGames.value.length),
    },
    {
      id: 'sport',
      label: t('search.sport'),
      counter: String(sportEvents.value.length),
    },
    {
      id: 'cybersport',
      label: t('search.cybersport'),
      counter: String(cybersportEvents.value.length),
    },
  ])

  const { currentPage } = useMatchLinkToRoute()

  onMounted(() => {
    switch (currentPage.value) {
      case 'sport':
        selectedTab.value = 'sport'
        break
      case 'cybersport':
        selectedTab.value = 'cybersport'
        break
      case 'casino':
        selectedTab.value = 'casino'
        break

      default:
        selectedTab.value = 'casino'
        break
    }
  })

  const isLoading = computed(() => casinoPending.value)

  return {
    tabs,
    resetSearch,
    searchValue,
    sportEvents,
    cybersportEvents,
    casinoGames,
    selectedTab,
    isLoading,
  }
}
