import type { TSportEvent } from 'markets-store'
import { CYBER_SPORT_ID } from 'markets-store/constants'
import { debounce } from '@st/utils'

export interface SearchOptions {
  delay?: number
  startIndex?: number
}
export interface SearchEventsByType {
  sport: TSportEvent[]
  cybersport: TSportEvent[]
}

const SEARCH_START_INDEX = 2

export function useBookmakerSearch(
  searchValue: Ref<string>,
  { delay = 300, startIndex = SEARCH_START_INDEX }: SearchOptions = {},
) {
  const bookmakerResult = ref<TSportEvent[]>([])
  const allEvents = computed(() =>
    bookmakerResult.value.reduce<SearchEventsByType>(
      (acc, item) => {
        if (item.sportId === CYBER_SPORT_ID) {
          acc.cybersport.push(item)
        } else acc.sport.push(item)

        return acc
      },
      { sport: [], cybersport: [] },
    ),
  )
  const cybersportEvents = computed(() => allEvents.value.cybersport)
  const sportEvents = computed(() => allEvents.value.sport)

  const findEvents = useFindByCompetitorName()
  function searchBookmakerEvents(query: string) {
    bookmakerResult.value = findEvents(query)
  }

  function reset() {
    bookmakerResult.value = []
  }
  const debounceSearch = debounce(searchBookmakerEvents, delay)
  watch(
    () => searchValue.value,
    (newValue) => {
      if (!newValue) reset()

      if (newValue.length > startIndex) debounceSearch(newValue)
    },
  )

  return {
    cybersportEvents,
    sportEvents,
    reset,
  }
}
