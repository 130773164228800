import { debounce } from '@st/utils'
import type { Game } from '../types'

export interface SearchOptions {
  delay?: number
  startIndex?: number
}

const SEARCH_START_INDEX = 2

export function useCasinoSearch(
  searchValue: Ref<string>,
  { delay = 300, startIndex = SEARCH_START_INDEX }: SearchOptions = {},
) {
  const casinoGames = ref<Game[]>([])

  const { isMobile } = usePlatform()
  const {
    data,
    execute,
    pending: casinoPending,
  } = useStFetch('/casino/game/find', {
    method: 'post',
    body: computed(() => ({
      params: {
        searchTerm: searchValue.value,
        ...(isMobile.value ? { forMobile: true } : { forDesktop: true }),
      },
      pagination: {
        orderBy: [
          {
            fieldName: 'name' as const,
            sortOrder: 'DESC' as const,
          },
        ],
      },
    })),
    immediate: false,
    watch: false,
  })

  async function searchCasinoGames() {
    await execute()
    casinoGames.value = data.value?.data ?? []
  }

  function reset() {
    casinoGames.value = []
  }

  const debounceSearch = debounce(searchCasinoGames, delay)
  watch(
    () => searchValue.value,
    (newValue) => {
      if (!newValue) reset()

      if (newValue.length > startIndex) debounceSearch()
    },
  )

  return {
    casinoGames,
    reset,
    casinoPending,
  }
}
